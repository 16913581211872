import React, { useState, useContext, useEffect } from "react";
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from "next/router";
import Footer from './common/Footer'
import axios from "axios";
import Cookies from "js-cookie";
import { ClientContext } from 'graphql-hooks'
import { removeCookies } from './common/Utils'
import { Store } from '../store'
import { Turnstile } from '@marsidev/react-turnstile'
import { validateEmail } from './common/Utils'

const Login = props => {
  const router = useRouter();
  const { state, dispatch } = useContext(Store)
  const [passwordLogin, setPasswordLogin] = useState(false);
  const [forcePasswordLogin, setForcePasswordLogin] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState(typeof localStorage !== 'undefined' ? localStorage.getItem('email') || '' : '')
  const [token, setToken] = useState()
  const EXPIRY_DAYS = 60

  const [password, setPassword] = useState()
  const client = useContext(ClientContext)

  useEffect(() => {
    if(router.query.password == 'true') {
      setPasswordLogin(true)
      setForcePasswordLogin(true)
    }
  }, [router.query.password]);

  useEffect(() => {
    if(['confirmed', 'reset', 'user_exists'].includes(router.query.message)) {
      setPasswordLogin(true)
      setForcePasswordLogin(true)
    }
  }, [router.query.message]);

  useEffect(() => {
    if(state.currentUser && state.currentUser.email) setEmail(state.currentUser.email)
  }, [state.currentUser]);

  const handleSubmit = (event) => {
    setSubmit(true)
    event.preventDefault();

    if(passwordLogin) {
      handlePasswordSubmit()
    } else {
      handleEmailSubmit()
    }
  }

  const handleEmailSubmit = () => {
    if (!validateEmail(email)) {
      setErrorMessage("メールアドレスのフォーマットに誤りがあります");
      setSubmit(false)
      setIsError(true);
      return;
    }
    const axiosInstance = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_API}`,
      headers: {
        "content-type": "application/json",
      },
    });
    (async () => {
      setIsError(false);
      setErrorMessage("");
      return await axiosInstance
        .post("auth/subscriber_sign_in", {
          email: email,
          token: token,
          subdomain: window.location.hostname.split('.')[0],
        })
        .then(function (response) {
          router.push('/messages/subscriberEmail', undefined, { shallow: false });
        })
        .catch(function (error) {
          setSubmit(false);
          if(error.response.data.errors === 'not_found') {
            router.push('/messages/subscriberEmail', undefined, { shallow: false });
          } else {
            removeCookies()
            setIsError(true);
            if (error.response.data.errors == "turnstile") {
              setErrorMessage(
                "エラー：ボットブロックが入りました。リロードしてもう一度お試しください"
              );
            } else {
              setErrorMessage(errorMessages(error.response.data.errors));
            }
            if(error.response.data.errors == 'user_exists') {
              setForcePasswordLogin(true)
            }
          }
        });
    })();
  }

  const errorMessages = (error) => {
    switch (error) {
      case 'user_exists':
        setPasswordLogin(true)
        return '既にパスワードが登録されています。パスワードでログインしてください'
      default: return error
    }
  }

  const handlePasswordSubmit = () => {
    if (!validateEmail(email)) {
      setErrorMessage("メールアドレスのフォーマットに誤りがあります");
      setSubmit(false)
      setIsError(true);
      return;
    }
    const axiosInstance = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_API}`,
      headers: {
        "content-type": "application/json",
      },
    });
    (async () => {
      setIsError(false);
      setErrorMessage("");
      return await axiosInstance
        .post("auth/sign_in", {
          email: email,
          password: password,
        })
        .then(function (response) {
          client.setHeader('uid', response.headers["uid"])
          client.setHeader('client', response.headers["client"])
          client.setHeader('access-token', response.headers["access-token"])

          if (process.env.NEXT_PUBLIC_DOMAIN === 'localhost') {
            Cookies.set("uid", response.headers["uid"], { expires: EXPIRY_DAYS });
            Cookies.set("client", response.headers["client"], { expires: EXPIRY_DAYS });
            Cookies.set("access-token", response.headers["access-token"], { expires: EXPIRY_DAYS });
          } else {
            Cookies.set("uid", response.headers["uid"], {
              expires: EXPIRY_DAYS,
              domain: `.${process.env.NEXT_PUBLIC_DOMAIN}`,
              secure: true,
            });
            localStorage.setItem('uid', response.headers["uid"])
            Cookies.set("client", response.headers["client"], {
              expires: EXPIRY_DAYS,
              domain: `.${process.env.NEXT_PUBLIC_DOMAIN}`,
              secure: true,
            });
            localStorage.setItem('client', response.headers["client"])
            Cookies.set("access-token", response.headers["access-token"], {
              expires: EXPIRY_DAYS,
              domain: `.${process.env.NEXT_PUBLIC_DOMAIN}`,
              secure: true,
            });
            localStorage.setItem('access-token', response.headers["access-token"])
          }
          if(Cookies.get('paid_url')) {
            const jump_url = Cookies.get("paid_url");
            Cookies.remove("paid_url");
            Cookies.remove("paid_url", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
            router.push(jump_url, undefined, { shallow: false });
          } else if(Cookies.get('checkout')){
            const post_id = Cookies.get("checkout");
            Cookies.remove("checkout");
            Cookies.remove("checkout", { domain: `.${process.env.NEXT_PUBLIC_DOMAIN}` });
            router.push(`/checkout?post_id=${post_id}`, undefined, { shallow: false });
          } else if(Cookies.get('writer')){
            Cookies.remove("writer");
            router.push(`/writer/1`, undefined, { shallow: false });
          } else {
            if(['admin', 'verified_writer'].includes(response.data.data.role)) {
              setTimeout(() => {
                router.push(`${process.env.NEXT_PUBLIC_FRONT_URL}workspace`, undefined, { shallow: false });
              }, 100);
            } else {
              setTimeout(() => {
                router.push('/', undefined, { shallow: false });
              }, 100);
            }
          }
        })
        .catch(function (error) {
          setSubmit(false);
          // removeCookies()

          setIsError(true);

          console.log(error)
          if (error.response) {
            setErrorMessage(errorMessagesLocale(error.response.data?.errors[0]));
          } else if (error.message && error.message.includes("Network Error")) {
            setErrorMessage("サーバーに不具合があります。管理者にご連絡ください");
            throw new Error('Login CORS error', error);
          } else if (error.request) {
            // Request was made but no response was received
            setErrorMessage("サーバーの応答がありません。もう１度試してみてください");
            throw new Error('Login error server response', error);
          } else {
            setErrorMessage("An unexpected error occurred.");
            throw new Error('Login error', error);
          }
        });
    })();
  };

  const errorMessagesLocale = (message) => {
    switch (message) {
      case 'Invalid login credentials. Please try again.':
        return 'メールアドレスまたはパスワードが間違っています'
      default: return message
    }
  }

  const message = () => {
    switch(router.query.message) {
      case 'confirmationCompleted':
        return(
          <div className='px-14 mt-6'>
            <div className='bg-gray-100 px-3 py-6 rounded'>
              <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
              メールアドレスの認証が完了しました
            </div>
          </div>
        )
      case 'reset':
        return(
          <div className='bg-gray-100 max-w-xl p-4 mx-auto rounded flex'>
            <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
            <div>
              パスワードのリセットが完了しました
            </div>
          </div>
        )
      case 'confirmed':
        return(
          <div className='bg-gray-100 max-w-xl p-4 mx-auto rounded flex'>
            <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
            <div>
              メールアドレスの確認が完了しました
            </div>
          </div>
        )
      case 'tokenError':
        return(
          <div className='bg-gray-100 max-w-xl p-4 mx-auto rounded flex'>
            <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
            <div>
              URLが無効です。再度ログインメールを送信してください
            </div>
          </div>
        )
      case 'user_exists':
        return(
          <div className='bg-gray-100 max-w-xl p-4 mx-auto rounded flex'>
            <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
            <div>
              既にパスワードが登録されています。パスワードでログインしてください
            </div>
          </div>
        )
      case 'needed':
        return(
          <div className='bg-gray-100 max-w-xl p-4 mx-auto rounded flex'>
            <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
            <div>
              手続きの前にログインが必要です。未登録の場合は
              <Link href={`/signup`} className='underline'>
              パスワード登録
              </Link>
              してください
            </div>
          </div>
        )
      case 'session':
        return(
          <div className='bg-gray-100 max-w-xl p-4 mx-auto rounded flex'>
            <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
            <div>
              ログインセッションが切れてしまいました。
              再度ログインしてください
            </div>
          </div>
        )
      case 'like':
        return(
          <div className='bg-gray-100 max-w-xl p-4 mx-auto rounded flex'>
            <img src='/images/check.svg' className='w-7 inline mr-2' alt='check' />
            <div>
              いいねボタンを押すにはログインが必要です
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const inputEmail = (email) => {
    setEmail(email)
    localStorage.setItem('email', email)
  }

  const submitButton = () => {
    if(!token && !passwordLogin) {
      return (
        <button
          disabled
          className="relative w-full bg-gray-500 text-white rounded px-4 py-2 bg-gray-200 shadow-md"
        >
          <svg
            className="animate-spin mr-2 inline h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <div className="inline text-gray-400">
            <>メールでログインする</>
          </div>
          <div className="absolute right-8 mx-auto w-fit  -bottom-5 text-gray-500 text-xs">
            ボット確認中...
          </div>
        </button>
      );
    }

    if(submit) {
      return (
        <button  disabled
        className='w-full bg-gray-500 text-white rounded px-4 py-2 bg-gray-200 shadow-md'>
        <svg className="animate-spin mr-2 inline h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Loading...
      </button>
      )
    }

    return (
      <button
        type="submit"
        className="w-full bg-gray-500 text-white rounded px-4 py-2 bg-gray-200 shadow-md"
        onMouseDown={handleSubmit}
      >
      {passwordLogin ? (
        <>パスワードでログインする</>
      ) : (
        <>メールでログインする</>
      )}
      </button>
    );
  }



  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>

      <div className='w-full border-b relative flex items-center justify-center text-gray-600 text-sm h-12'>
        <Link href={`/signup`}
          className='pt-4 px-6 h-full border-b-2 border-white hover:bg-gray-100 hover:border-gray-100'>
          パスワード登録
        </Link>
        <div className='pt-4 px-6 h-full border-b-2 border-gray-900 '>
          ログイン
        </div>
      </div>

        <div className="max-w-xl mx-auto mt-8 px-4">
          {message()}
          <img src='/images/mail.svg' className='mx-auto w-10 mt-10' alt='mail'  />
          <img src='/images/title.webp' className='mx-auto w-60' alt='みんなのニュースレター' />

          <div className='mt-16 mx-auto w-fit'>
            ログイン
          </div>

          <div className='mt-6 mx-auto w-full px-2 md:px-14 '>
            <input
              type='email'
              id="email"
              name="email"
              placeholder='メールアドレス'
              className='w-full px-4 py-2 bg-gray-200 rounded'
              value={email}
              onChange={e => inputEmail(e.target.value)}
            />
          </div>

          { passwordLogin ? (
            <>
              <div className='mt-6 mx-auto w-full px-2 md:px-14'>
                <input
                  type='password'
                  name="password"
                  id="password"
                  placeholder='パスワード'
                  className='w-full px-4 py-2 bg-gray-200'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            {!forcePasswordLogin &&
              <div className='text-right text-sm font-bold mt-1 w-full px-2 md:px-14 flex items-center'
                onClick={e => setPasswordLogin(false)}>
                <div className="grow"></div>
                <img src='/images/toggle-right.svg' className='w-4 mr-1' alt='right' />
                <div className="hover:underline">
                  メールでログイン
                </div>
              </div>
            }
            </>
          ) : (
            <div className='text-right text-sm font-bold mt-1 w-full px-2 md:px-14 flex items-center'
            onClick={e => setPasswordLogin(true)}>
              <div className="grow"></div>
              <img src='/images/toggle-right.svg' className='w-4 mr-1' alt='right' />
              <div className="hover:underline">
              パスワードでログイン
              </div>
            </div>
          ) }

          <div className='mt-12 mx-auto w-full  px-2 md:px-14'>
            {submitButton()}

          {isError ? (
            <div
              className='w-full mt-2 text-red-800 text-sm'
              onMouseDown={() => {
                setIsError(false);
                setErrorMessage("");
              }}
            >
              {errorMessage}
            </div>
          ) : null}
          <Turnstile
            siteKey={process.env.NEXT_PUBLIC_TURNSTILE}
            onSuccess={(token) => setToken(token)}
            options={{ size: 'invisible'}}
            onError={() => {
              setErrorMessage(
                "エラー：ボットブロックが入りました。リロードしてもう一度お試しください"
              );
            }}
          />
        </div>
        <Link href={`/resetPassword`}>
          <div className='mt-12 mx-auto w-full  px-2 md:px-14 text-right underline hover:text-gray-400'>
            パスワードを忘れた？
          </div>
        </Link>
        </div>
    <Footer />
    </>
  );
};

export default Login
